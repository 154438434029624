<template>
    <div class="admin-content">
        <div class="main-wrapper">
            <el-form :model="addHelpForm" ref="addHelpForm" :rules="helpRules" label-position="left"
                     class="help-info">
                <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
                    <el-input v-model="addHelpForm.title" placeholder="请输入标题" style="width: 220px;" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="选择分类" :label-width="formLabelWidth" prop="help_id" class="class-item">
                    <el-cascader
                            v-model="addHelpForm.help_id"
                            :options="helpClass"
                            :props="classProps"
                            @change="handleClass"></el-cascader>
                </el-form-item>
                <el-form-item label="内容详情" prop="content" class="content-item">
                    <div id="help_content"></div>
                </el-form-item>
                <el-form-item class="save-item">
                    <el-button size="mini" type="primary" @click="saveHelp('addHelpForm')">保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import WE from "../../utils/wEditor";

    export default {
        name: "CreateHelp",
        data() {
            return {
                //帮助中心表单数据
                addHelpForm: {
                    title: '',
                    help_id: '',
                    content: ''
                },
                helpRules: {
                    title: [
                        {required: true, message: '请输入标题', trigger: 'blur'},
                        {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}
                    ],
                    help_id: [
                        {required: true, message: '请选择分类', trigger: 'change'},
                    ],
                    content: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                        { min: 3, max: 5000, message: "长度在 3 到 5000 个字符", trigger: "blur" }
                    ]
                },
                //帮助的分类
                helpClass: [],
                classProps: {
                    value: 'id',
                    label: 'name'
                },
                formLabelWidth: '80px',
                editorW: null,
            }
        },
        mounted() {
            this.getHelpClass();
            this.editorW = new WE('#help_content', this.$api.help_upload,['head', 'bold', 'quote', 'underline', 'code', 'link', 'image', 'video']);
            this.getHelpDetail();
        },
        methods: {
            //获取帮助分类
            getHelpClass() {
                this.$http.axiosGet(this.$api.helpClassification, (res) => {
                    if (res.code === 200) {
                        this.helpClass = res.data.data;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            handleClass(val) {
                if (val) {
                    this.addHelpForm.help_id = val[val.length - 1];
                }
            },
            //获取单条帮助信息
            getHelpDetail() {
                this.$http.axiosGetBy(this.$api.help, {id: this.$route.query.id}, (res) => {
                    if (res.code === 200) {
                        this.addHelpForm = res.data;
                        this.editorW.setWEHtml(res.data.content);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //保存
            saveHelp(formName) {
                this.addHelpForm.content = this.editorW.getWEHtml();
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('title', this.addHelpForm.title);
                        formData.append('help_id', this.addHelpForm.help_id);
                        formData.append('content', this.addHelpForm.content);
                        if (this.$route.query.id) {
                            formData.append('id', this.$route.query.id);
                        }
                        this.$http.axiosPost(this.$api.help, formData, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.message);
                                this.$router.push('/admin/help/index');
                            } else {
                                this.$message.warning(res.message)
                            }
                        }, (err) => {
                            console.log(err)
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .help-info {
        height: 100%;
        padding: 34px 20px;
        ::v-deep .el-form-item__label {
            text-align: right;
        }
        .class-item {
            border-bottom: 1px solid #DCDCDC;
            padding-bottom: 22px;
        }
        .content-item {
            display: flex;
            flex-direction: column;
            height: calc(100% - 187px);
            ::v-deep .el-form-item__content {
                height: calc(100% - 50px);
                #help_content {
                    height: 100%;
                    ::v-deep .w-e-text-container {
                        max-height: calc(100% - 52px) !important;
                        min-height: calc(100% - 52px) !important;
                    }
                }
            }
            ::v-deep .el-form-item__label {
                margin-bottom: 10px;
                width: 80px;
            }
        }
        .save-item {
            text-align: center;
            .el-button {
                background-color: #1E33E4;
                border-color: #1E33E4;
            }
        }
    }
</style>